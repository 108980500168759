$(function () {
  const btn = $("#page_top");
  const youtubearea = $("#ly_youtubeArea");
  const youtubearea_position = youtubearea.offset().top;
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > youtubearea_position) {
      btn.addClass("active");
    } else {
      btn.removeClass("active");
    }
  });

  btn.click(function () {
    $("html, body").animate({ scrollTop: youtubearea_position }, 500);
    return false;
  });
});
